import { privateAxios, publicAxios } from "axios.config"

export const handleTokenRefresh = async () => {
    return await privateAxios.post("/api/auth/refresh", {
        refresh: localStorage.getItem("RefreshToken"),
    })
}

export const updatePassword = async (old_password = "", new_password = "") => {
    return await privateAxios.post("/api/users/change-password", { old_password, new_password }) //need to fix
}

export const checkEmailAddress = async (email = "", username = "") => {
    return await publicAxios.post(`/api/auth/pre-create`, { username, email })
}

export const handleSignup = async (data) => {
    return await publicAxios.post(`/api/auth/signup`, data)
}

export const handleLogin = async (data) => {
    return await publicAxios.post(`/api/auth/`, data)
}

export const requestResetPassword = async (data) => {
    return await publicAxios.post(`/api/auth/request-reset-password`, data)
}

export const resetPassword = async (data) => {
    return await publicAxios.post(`/api/auth/reset-password`, data)
}
