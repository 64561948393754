import { privateAxios } from "axios.config"

export const ManageCandidateProfile = async (data) => {
    return await privateAxios.post(`/api/users/create-user-profile`, data)
}

export const ManageEmployeeProfile = async (data) => {
    return await privateAxios.post(`/api/users/create-employer-profile`, data)
}

export const AddConnection = async (data) => {
    return await privateAxios.post(`/api/connections/`, data)
}

export const GetConnections = async () => {
    return await privateAxios.get(`/api/connections/`)
}

export const GetOnlineConnections = async () => {
    return await privateAxios.get(`/api/connections/my-online-connected-users/`)
}

export const GetOfflineConnections = async () => {
    return await privateAxios.get(`/api/connections/my-offline-connected-users/`)
}

export const GetUserDetails = async () => {
    return await privateAxios.get(`/api/users/me`)
}

export const DeleteProfile = async () => {
    return await privateAxios.delete(`/api/users/me`)
}

export const DeactivateProfile = async (id) => {
    return await privateAxios.delete(`/api/users/${id}`)
}

export const UpdateAvatar = async (id, avatar_id) => {
    return await privateAxios.patch(`/api/users/${id}`, { avatar: avatar_id })
}

export const GetDashboardDetails = async () => {
    return await privateAxios.get(`/api/landing-page`)
}

export const SearchUsers = async (query) => {
    return await privateAxios.get(`/api/users/?search=${query}`)
}
