import React from "react"
import { Image } from "react-bootstrap"

import Logo from "@assets/logo/logo.png"
import "./LoadingState.scss";

const LoadingState = () => {
    return (
        <React.Fragment>
            <div className="fullpage-loader">
                <div className="fullpage-loader__logo">
                    <Image
                        fluid
                        width={"200px"}
                        src={Logo}
                        className="loading-logo"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default LoadingState
