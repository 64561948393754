import { lazy } from "react"

export const publicRoutes = [
    {
        id: 1,
        text: "Home",
        link: "/",
        component: lazy(() => import("../pages/Home/Home")),
    },
    {
        id: 2,
        text: "Career",
        link: "/career",
        component: lazy(() => import("../pages/Career/Career")),
    },
    {
        id: 3,
        text: "Career",
        hidden: true,
        link: "/career-development",
        component: lazy(() => import("../pages/Career/CareerDevelopment")),
    },
    {
        id: 4,
        text: "About Us",
        children: [
            {
                id: 4.1,
                text: "About Company",
                link: "/about",
                component: lazy(() => import("../pages/About/About")),
            },
            {
                id: 4.2,
                text: "What we do",
                link: "/what-we-do",
                component: lazy(() => import("../pages/Goal/Goal")),
            },
            {
                id: 4.3,
                text: "Leadership",
                link: "/leadership",
                component: lazy(() => import("../pages/Leadership/Leadership")),
            },
        ],
    },
    {
        id: 5,
        text: "Contact Us",
        link: "/contact",
        hidden: true,
        component: lazy(() => import("../pages/Contact/Contact")),
    },
    {
        id: 6,
        text: "Sign up",
        link: "/sign-up",
        button: true,
        component: lazy(() => import("../pages/Registration/Registration")),
    },
    {
        id: 7,
        text: "Sign in",
        link: "/sign-in",
        button: true,
        component: lazy(() => import("../pages/Login/Login")),
    },
    {
        id: 8,
        text: "Workforce Management",
        link: "/workforce",
        hidden: true,
        component: lazy(() => import("../pages/Workforce/Workforce")),
    },
    {
        id: 9,
        text: "Business Management",
        link: "/business-management",
        hidden: true,
        component: lazy(() => import("../pages/Business/Business")),
    },
    {
        id: 10,
        text: "Connectivity",
        link: "/connectivity",
        hidden: true,
        component: lazy(() => import("../pages/Connectivity/Connectivity")),
    },
    {
        id: 11,
        text: "Logout",
        link: "/logout",
        hidden: true,
        component: lazy(() => import("../pages/Logout/Logout")),
    },
    {
        id: 12,
        text: "Forgot Password",
        link: "/forgot-password",
        hidden: true,
        component: lazy(() => import("../pages/Forgot/Forgot")),
    },
    {
        id: 13,
        text: "Reset Password",
        link: "/reset-password/:security/:token",
        hidden: true,
        component: lazy(() => import("../pages/Forgot/Reset")),
    },
]

export const adminRoutes = [
    {
        id: 1,
        text: "News",
        link: "/admin-news",
        protected: true,
        component: lazy(() => import("../pages/Admin/News")),
    },
    {
        id: 2,
        text: "Update Terms & Conditions",
        link: "/admin-update-terms",
        protected: true,
        component: lazy(() => import("../pages/Admin/UpdateTerms")),
    },
    {
        id: 3,
        text: "Update Privacy Statement",
        link: "/admin-privacy-statement",
        protected: true,
        component: lazy(() => import("../pages/Admin/UpdatePrivacyStatement")),
    },
    {
        id: 4,
        text: "Settings",
        children: [
            {
                id: 4.1,
                text: "Accounts",
                children: [
                    {
                        id: 4.1_2,
                        text: "Edit Password",
                        link: "/admin-edit-password",
                        protected: true,
                        component: lazy(() =>
                            import("../pages/Settings/EditPassword")
                        ),
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        text: "Contact Us",
        link: "/contact",
        hidden: true,
        component: lazy(() => import("../pages/Contact/Contact")),
    },
    {
        id: 6,
        text: "Logout",
        link: "/logout",
        button: true,
        component: lazy(() => import("../pages/Logout/Logout")),
    },
]

export const employerRoutes = [
    {
        id: 1,
        text: "Dashboard",
        link: "/employer",
        protected: true,
        component: lazy(() => import("../pages/Dashboard/Dashboard")),
    },
    {
        id: 2,
        text: "Search Candidates",
        link: "/search-users",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Search/Search")),
    },
    {
        id: 3,
        text: "Employer's Career",
        link: "/employer-career",
        protected: true,
        component: lazy(() => import("../pages/Career/Career")),
    },
    {
        id: 4,
        text: "Publish Job",
        link: "/publish-job",
        protected: true,
        component: lazy(() => import("../pages/Publish/Publish")),
    },
    {
        id: 5,
        text: "Choose Our Template",
        link: "/employer-publish-job",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Template/Template")),
    },
    {
        id: 6,
        text: "Update Job",
        link: "/employer-update-job/:id",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Template/EmployerTemplate")),
    },
    {
        id: 7,
        text: "Alerts",
        link: "/employer-alerts",
        protected: true,
        component: lazy(() => import("../pages/Alerts/Alerts")),
    },
    {
        id: 8,
        text: "Messages",
        link: "/employer-messages",
        protected: true,
        component: lazy(() => import("../pages/Messages/Messages")),
    },
    {
        id: 9,
        text: "Settings",
        children: [
            {
                id: 9.1,
                text: "Accounts",
                children: [
                    {
                        id: 9.1_1,
                        text: "Manage Jobs",
                        link: "/employer-jobs",
                        protected: true,
                        component: lazy(() =>
                            import("../pages/Settings/Manage/EmployerJobs")
                        ),
                    },
                    {
                        id: 9.1_2,
                        text: "Edit Profile",
                        link: "/employer-profile",
                        protected: true,
                        component: lazy(() =>
                            import("../pages/Settings/Profile/EditProfile")
                        ),
                    },
                    {
                        id: 9.1_3,
                        text: "Edit Password",
                        link: "/employer-edit-password",
                        protected: true,
                        component: lazy(() =>
                            import("../pages/Settings/EditPassword")
                        ),
                    },
                ],
            },
            {
                id: 9.2,
                text: "Payment",
                children: [
                    {
                        id: 9.2_1,
                        text: "Edit Card",
                        link: "/employer-edit-card",
                        protected: true,
                        component: lazy(() => import("../pages/Dashboard/Dashboard")),
                    },
                    {
                        id: 9.2_2,
                        text: "Pay For Service",
                        link: "/employer-pay-for-service",
                        protected: true,
                        component: lazy(() => import("../pages/Dashboard/Dashboard")),
                    },
                    {
                        id: 9.2_3,
                        text: "Pay Invoices",
                        link: "/employer-pay-invoices",
                        protected: true,
                        component: lazy(() => import("../pages/Dashboard/Dashboard")),
                    },
                    {
                        id: 9.2_4,
                        text: "Buy Services",
                        link: "/employer-buy-services",
                        protected: true,
                        component: lazy(() => import("../pages/Settings/Services/BuyServices")),
                    },
                ],
            }
        ],
    },
    {
        id: 10,
        text: "Contact Us",
        link: "/contact",
        hidden: true,
        component: lazy(() => import("../pages/Contact/Contact")),
    },
    {
        id: 11,
        text: "Logout",
        link: "/logout",
        button: true,
        component: lazy(() => import("../pages/Logout/Logout")),
    },
    {
        id: 12,
        text: "Connections",
        link: "/my-connections",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Connections/Connections")),
    },
]

export const candidateRoutes = [
    {
        id: 1,
        text: "Dashboard",
        link: "/candidate",
        protected: true,
        component: lazy(() => import("../pages/Dashboard/Dashboard")),
    },
    {
        id: 2,
        text: "Search Candidates",
        link: "/search-users",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Search/Search")),
    },
    {
        id: 3,
        text: "Candidates's Career",
        link: "/candidate-career",
        protected: true,
        component: lazy(() => import("../pages/Career/Career")),
    },
    {
        id: 4,
        text: "Upload Resume",
        link: "/upload-resume",
        protected: true,
        component: lazy(() => import("../pages/Publish/Publish")),
    },
    {
        id: 5,
        text: "Choose Our Template",
        link: "/candidate-upload-resume",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Template/Template")),
    },
    {
        id: 6,
        text: "Update Resume",
        link: "/candidate-update-resume/:id",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Template/CandidateTemplate")),
    },
    {
        id: 6,
        text: "Alerts",
        link: "/candidate-alerts",
        protected: true,
        component: lazy(() => import("../pages/Alerts/Alerts")),
    },
    {
        id: 7,
        text: "Messages",
        link: "/candidate-messages",
        protected: true,
        component: lazy(() => import("../pages/Messages/Messages")),
    },
    {
        id: 8,
        text: "Settings",
        children: [
            {
                id: 8.1,
                text: "Accounts",
                children: [
                    {
                        id: 8.1_1,
                        text: "Manage Resumes",
                        link: "/candidate-resumes",
                        protected: true,
                        component: lazy(() =>
                            import("../pages/Settings/Manage/CandidateResumes")
                        ),
                    },
                    {
                        id: 8.1_2,
                        text: "Edit Profile",
                        link: "/candidate-profile",
                        protected: true,
                        component: lazy(() =>
                            import("../pages/Settings/Profile/EditProfile")
                        ),
                    },
                    {
                        id: 8.1_3,
                        text: "Edit Password",
                        link: "/candidate-edit-password",
                        protected: true,
                        component: lazy(() =>
                            import("../pages/Settings/EditPassword")
                        ),
                    },
                ],
            },
            {
                id: 8.2,
                text: "Payment",
                children: [
                    {
                        id: 8.2_1,
                        text: "Edit Card",
                        link: "/candidate-edit-card",
                        protected: true,
                        component: lazy(() => import("../pages/Dashboard/Dashboard")),
                    },
                    {
                        id: 8.2_2,
                        text: "Pay For Service",
                        link: "/candidate-pay-for-service",
                        protected: true,
                        component: lazy(() => import("../pages/Dashboard/Dashboard")),
                    },
                    {
                        id: 8.2_3,
                        text: "Pay Invoices",
                        link: "/candidate-pay-invoices",
                        protected: true,
                        component: lazy(() => import("../pages/Dashboard/Dashboard")),
                    },
                    {
                        id: 8.2_4,
                        text: "Buy Services",
                        link: "/candidate-buy-services",
                        protected: true,
                        component: lazy(() => import("../pages/Settings/Services/BuyServices")),
                    },
                ],
            }
        ],
    },
    {
        id: 9,
        text: "Contact Us",
        link: "/contact",
        hidden: true,
        component: lazy(() => import("../pages/Contact/Contact")),
    },
    {
        id: 10,
        text: "Logout",
        link: "/logout",
        button: true,
        component: lazy(() => import("../pages/Logout/Logout")),
    },
    {
        id: 11,
        text: "Connections",
        link: "/my-connections",
        protected: true,
        hidden: true,
        component: lazy(() => import("../pages/Connections/Connections")),
    },
]
