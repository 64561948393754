import { handleTokenRefresh } from "@api/authenticate"
import axios from "axios"

let isAlreadyFetchingAccessToken = false

let subscribers = []

// const URL = `https://newgenznbackend.herokuapp.com/` //PROD
// const URL = `https://dev-newgenzn-backend.herokuapp.com` // Dev
const URL = process.env.REACT_APP_API_URL //`https://craft-position-backend-development.onrender.com/` // Dev

export const publicAxios = axios.create({
    baseURL: URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
})

export const privateAxios = axios.create({
    baseURL: URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
})

privateAxios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        const access_token = localStorage.getItem("Token")
        if (error?.response?.status === 401 && access_token) {
            return ResetTokenAndReattemptRequest(error)
        } else {
            return Promise.reject(error)
        }
    }
)

privateAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem("Token")
    if (token) {
        // or get it from localStorage
        config.headers["Authorization"] = "Bearer " + token
    }
    return config
})

export async function ResetTokenAndReattemptRequest(error) {
    try {
        const { response: errorResponse } = error
        const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
                errorResponse.config.headers.Authorization =
                    "Bearer " + access_token
                resolve(axios(errorResponse.config))
            })
        })

        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true

            handleTokenRefresh().then(function (response) {
                    localStorage.setItem("Token", response.data.access)
                }).catch(function (error) {
                    return Promise.reject(error)
                })

            isAlreadyFetchingAccessToken = false
            onAccessTokenFetched(localStorage.getItem("Token"))
        }

        return retryOriginalRequest
    } catch (err) {
        return Promise.reject(err)
    }
}

function onAccessTokenFetched(access_token) {
    subscribers.forEach((callback) => callback(access_token))
    subscribers = []
}

function addSubscriber(callback) {
    subscribers.push(callback)
}
