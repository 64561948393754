import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"

import AuthProvider from "@src/context/auth"

import App from "./App"
import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <HelmetProvider>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </HelmetProvider>
        </BrowserRouter>
    </React.StrictMode>
)
