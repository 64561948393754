import React, { useRef, useState } from "react"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { Button, Form, Image, Modal, Nav, Navbar } from "react-bootstrap"
import { Formik } from "formik"
import * as yup from "yup"

import { useAuth } from "@src/context"

import { filterRecord } from "@helpers/GlobalFunctions"
import MobileHeader from "./MobileHeader"

import Logo from "@assets/logo/logo.png"
import "./Header.scss"

const Header = () => {
    const { routes, isLogin } = useAuth()
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
        <React.Fragment>
            <Navbar
                bg="white"
                expand="lg"
                sticky="top"
                className="m-lg-0 m-3 nav-shadow"
            >
                <div className="container-fluid px-lg-5 px-sm-3">
                    <Button
                        onClick={handleShow}
                        variant={"light"}
                        className="toggle-menu d-lg-none d-block"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </Button>
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        className="d-lg-block d-none"
                    >
                        <Image src={Logo} fluid width={100} />
                    </Navbar.Brand>
                    {isLogin && (
                        <SearchInput
                            id="formBasicMobileSearch"
                            className="d-lg-none d-block"
                        />
                    )}
                    <Navbar.Collapse
                        id="navbar--nav"
                        data-animations="fadeInUp fadeInLeft fadeInUp fadeInRight"
                    >
                        <Nav className="ms-auto flex-wrap justify-content-end">
                            {isLogin && (
                                <Nav.Item className="d-lg-block d-none mt-xl-0 mt-3 me-2">
                                    <SearchInput id="formBasicDesktopSearch" />
                                </Nav.Item>
                            )}
                            {routes.map((menu, index) => {
                                const depthLevel = 0
                                if (menu && !menu.hidden) {
                                    return (
                                        <MenuItems
                                            items={menu}
                                            key={index}
                                            depthLevel={depthLevel}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </Nav>
                    </Navbar.Collapse>
                </div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    className="nav-left d-lg-none d-block"
                    dialogClassName="position-fixed m-auto"
                    contentClassName="h-100 border-0 rounded-0"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Image src={Logo} fluid width={100} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MobileHeader
                            routes={routes}
                            handleClose={handleClose}
                        />
                    </Modal.Body>
                </Modal>
            </Navbar>
        </React.Fragment>
    )
}
const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false)
    const [toggled, setToggle] = useState(false)
    const { pathname } = useLocation()

    const navItemRef = useRef()
    const isChildToggled = useRef(false)

    React.useEffect(() => {
        const handler = (event) => {
            if (
                dropdown &&
                navItemRef.current &&
                !navItemRef.current.contains(event.target)
            ) {
                setDropdown(false)
            }
        }
        document.addEventListener("mousedown", handler)
        document.addEventListener("touchstart", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
            document.removeEventListener("touchstart", handler)
        }
    }, [dropdown])

    React.useEffect(() => {
        if (!isChildToggled.current) {
            isChildToggled.current = true
            if (items.children && !toggled) {
                if (filterRecord(items.children, "link", pathname)) {
                    setToggle(true)
                }
            }
        }
    }, [items.children, toggled, pathname])

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true)
    }

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false)
    }

    return (
        <Nav.Item
            className="menu-items my-xl-0 my-lg-2"
            ref={navItemRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {items.children ? (
                <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between">
                        <Nav.Link
                            type="button"
                            aria-haspopup="menu"
                            className={`menu-item-link ${dropdown || toggled ? "active" : ""}`}
                            aria-expanded={dropdown ? "true" : "false"}
                            onClick={() => setDropdown((prev) => !prev)}
                        >
                            {items.text}
                        </Nav.Link>
                        <div className="p-1">
                            {depthLevel > 0 ? (
                                <span className="arrow arrow-right" />
                            ) : (
                                <span className="arrow" />
                            )}
                        </div>
                    </div>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.children}
                        dropdown={dropdown}
                    />
                </React.Fragment>
            ) : !items.button ? (
                <Nav.Link as={NavLink} to={items.link} className="menu-item-link mx-3">
                    {items.text}
                </Nav.Link>
            ) : (
                <Button
                    as={Link}
                    to={items.link}
                    className="btn btn-primary mx-3"
                >
                    {items.text}
                </Button>
            )}
        </Nav.Item>
    )
}

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
    depthLevel = depthLevel + 1
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : ""
    return (
        <Nav
            className={`navbar-dropdown pe-3 flex-column ${dropdown ? "show" : ""} ${dropdownClass} `}
        >
            {submenus.map((submenu, index) => (
                <MenuItems
                    items={submenu}
                    key={index}
                    depthLevel={depthLevel}
                />
            ))}
        </Nav>
    )
}

const SearchInput = ({ className = "", id = "formBasicSearch" }) => {

    
    const schema = yup.object().shape({
        query: yup.string().trim().required().min(3),
    })

    const navigate = useNavigate()


    return (
        <Formik
            initialValues={
                { query: "" }
            }
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
                navigate(`/search-users?query=${values.query}`)
                setSubmitting(false)
            }}>
            {
                ({ handleChange, handleSubmit, isSubmitting, values }) => (
                    <Form autoComplete="off" onSubmit={handleSubmit} className={className}>
                        <Form.Group
                            className={`form-group has-search`}
                            controlId={id}
                        >
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                name="query"
                                onChange={handleChange}
                                value={values.query}
                            />
                            <Button
                                variant="outline-light"
                                className="text-dark input-search-btn border-0 position-absolute top-50 translate-middle-y"
                                type="submit"
                                size="sm"
                                disabled={isSubmitting}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </Button>
                        </Form.Group>
                    </Form>
                )
            }
        </Formik>
    )
}

export default Header
