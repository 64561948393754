import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import "./ScrollToTop.scss"

const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false)

    useEffect(() => {
        const trackScroll = () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true)
            } else {
                setShowTopBtn(false)
            }
        }

        window.addEventListener("scroll", trackScroll)
        return () => window.removeEventListener("scroll", trackScroll)
    }, [])


    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }


    return (
        <div className="top-to-btm">
            {showTopBtn && (
                <Button
                    variant="primary"
                    className="icon-position icon-style"
                    onClick={handleScrollTop}
                >
                    <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                        />
                    </svg>
                </Button>
            )}
        </div>
    )
}

export default ScrollToTop
