import React, { createContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import withReactContent from "sweetalert2-react-content"
import jwt_decode from "jwt-decode"
import Swal from "sweetalert2"

import { isJson, isEmpty, ErrorHandling } from "@helpers/GlobalFunctions"
import { toast } from "@components/ToastExtended"
import { GetOnlineConnections, GetOfflineConnections } from '@api/users';
import {
    publicRoutes,
    candidateRoutes,
    employerRoutes,
    adminRoutes,
} from "@routes/routes"

const AuthProvider = ({ children }) => {

    const [isLoading, setLoading] = useState(true)
    const [loginInformation, setLoginInformation] = useState({
        isLogin: undefined,
        hasLogout: undefined,
        data: null,
    })

    const [routes, setRoutes] = useState([])
    const isCredentialsChecked = useRef(false)

    const Logout = () => {

        localStorage.removeItem("Token")
        localStorage.removeItem("RefreshToken")

        setLoginInformation({
            isLogin: false,
            hasLogout: true,
            data: null,
        })

        setRoutes(publicRoutes)
    }

    const tokenExpired = React.useCallback(() => {

        const MySwal = withReactContent(Swal)
        setLoading(false)
        MySwal.fire({
            title: "Session Expired",
            text: "Your login token has expired. Please login again thank you.",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: "Sign In Again",
            customClass: {
                confirmButton: "btn btn-info mx-2",
                cancelButton: "btn btn-light mx-2",
            },
            buttonsStyling: false,
        }).then((result) => {
            setLoading(true)
            if (result.isConfirmed) {
                Logout()
                window.location.replace(`/`)
                setLoading(false)

            } else {
                Logout()
                window.location.replace(`/`)
                setLoading(false)
            }
        })

    }, [])

    const [online, setOnline] = useState(false);
    useLayoutEffect(() => {

        const handleLogoutOnError = (error = "", ErrorType = "") => {
            Logout()
            window.location.replace(`/?error=${error}&type=${ErrorType}`)
        }

        if (!isCredentialsChecked.current) {
            isCredentialsChecked.current = true


            setLoading(true)
            let status = false
            let loggedInUser = localStorage.getItem("Token")

            if (loggedInUser) {
                try {
                    let decoded = jwt_decode(loggedInUser)
                    if (isJson(decoded) && !isEmpty(decoded)) {
                        if (new Date(decoded?.exp * 1000) > new Date()) {

                            status = true
                            if (decoded.is_admin === true) {
                                setRoutes(adminRoutes)
                            } else if (decoded.role === "candidate") {
                                setRoutes(candidateRoutes)
                            } else if (decoded.role === "employer") {
                                setRoutes(employerRoutes)
                            } else {
                                handleLogoutOnError("no valid user found. Signing out...", "token")
                                return
                            }

                            setLoginInformation((prev) => ({
                                ...prev,
                                isLogin: status,
                                data: decoded,
                            }))

                            setLoading(false)
                        } else {
                            tokenExpired()
                        }
                    } else {
                        handleLogoutOnError("Invalid token found. Signing out...", "token")
                        return
                    }
                } catch (error) {
                    handleLogoutOnError("Invalid token found. Signing out...", "token")
                    return;
                }
            } else {
                setLoginInformation((prev) => ({
                    ...prev,
                    isLogin: status,
                }))

                setRoutes(publicRoutes)
                setLoading(false)
            }
        }

    }, [tokenExpired])

    useEffect(() => {

        let _socket = null
        const socket_url = process.env.REACT_APP_WEBSOCKET;
        const token = localStorage.getItem("Token");
        if (token && loginInformation.data && loginInformation.isLogin) {
            setLoading(true)
            _socket = new WebSocket(`${socket_url}/set-user-status/?token=${token}`); // "wss://craft-position-backend-development.onrender.com/set-user-status/?token=" + token
            _socket.onopen = function (e) {
                console.log("Connection established! (1)", e);
                setOnline(true);
                setLoading(false)
            };

            _socket.onclose = async function (e) {
                console.log('close (1)', e)
                setOnline(false);
                setLoading(false)
            }
        } else {
            if (loginInformation.hasLogout) {
                if (_socket && _socket.readyState === 1) {
                    _socket.close();
                }
            }
        }


        return () => {
            if (_socket && _socket.readyState === 1) {
                _socket.close();
            }
        }

    }, [loginInformation])

    const setLoginStatus = (status = null, token) => {
        if (status !== null) {
            if (status) {
                try {
                    setLoading(true)
                    let decoded = jwt_decode(token)
                    if (decoded.is_admin === true) {
                        setRoutes(adminRoutes)
                    } else if (decoded.role === "candidate") {
                        setRoutes(candidateRoutes)
                    } else if (decoded.role === "employer") {
                        setRoutes(employerRoutes)
                    } else {
                        Logout()
                        window.location.replace(`/?error=Invalid token found. Signing out...&type=token`)
                        return
                    }

                    setLoginInformation((prev) => ({
                        ...prev,
                        isLogin: status,
                        hasLogout: null,
                        data: decoded,
                    }))

                    setLoading(false)
                } catch (error) {
                    Logout()
                    window.location.replace(`/?error=Invalid token found. Signing out...&type=token`)
                    return
                }
            }
        }
    }

    const [socket, setSocket] = useState(null);
    const [serverMessage, setServerMessage] = useState("");
    const [webSocketReady, setWebSocketReady] = useState(false);

    const [connections, setConnections] = useState({
        online: [],
        offline: [],
    })


    useEffect(() => {
        const fetchSearchResults = async () => {
            Promise.all([
                GetOnlineConnections(),
                GetOfflineConnections()
            ]).then((responses) => {
                const onlineList = responses[0];
                const offlineList = responses[1];
                setConnections(prev => {
                    const arr = [...onlineList.data, ...offlineList.data, ...prev.offline, ...prev.online]
                    prev.offline = arr.filter((value, index, self) =>
                        (index === self.findIndex((t) => (
                            (t.id === value.id)
                        ))) && value.online <= 0
                    )
                    prev.online = arr.filter((value, index, self) =>
                        (index === self.findIndex((t) => (
                            (t.id === value.id)
                        ))) && value.online > 0
                    )

                    return prev
                })
            })
                .catch((error) => {
                    toast.show({
                        type: "danger",
                        title: "Something went wrong",
                        description: ErrorHandling(error)
                    })
                })
        }

        const token = localStorage.getItem("Token");
        if (token && loginInformation.isLogin && online) {
            fetchSearchResults().finally(() => {
                const socket_url = process.env.REACT_APP_WEBSOCKET
                setSocket(
                    new WebSocket(`${socket_url}/my-connected-users/?token=${token}`) //"wss://craft-position-backend-development.onrender.com/my-connected-users/?token=" + token
                )
            })
            return;
        }

    }, [loginInformation.isLogin, online])

    useEffect(() => {

        if (socket) {

            socket.onopen = function (e) {
                setWebSocketReady(true);
                console.log("Connection established! (2)", e);
            };

            socket.onclose = async function (e) {
                console.log('close (2)', e)
                setWebSocketReady(false);
            }

            socket.onmessage = async function (e) {
                console.log('message (2)', e)
                setServerMessage(e.data)
            }

            socket.onerror = function (err) {
                console.log('Socket encountered error: ', err.message, 'Closing socket');
                setWebSocketReady(false);
                socket.close();
            };

            if (loginInformation.hasLogout) {
                socket.close();
                setConnections({
                    online: [],
                    offline: [],
                })
            }
        }

        return () => {
            if (socket) {
                socket.close();
            }
        }
    }, [socket, loginInformation.hasLogout])

    useEffect(() => {
        if (serverMessage !== "" && isJson(serverMessage)) {
            const user = JSON.parse(serverMessage);
            const meUser = loginInformation.data;
            if (user && meUser && user.id !== meUser.id) {
                setConnections(prev => {
                    const onlineList = prev.online.find((p) => p.id === user.id)
                    const offlineList = prev.offline.find((p) => p.id === user.id)

                    if (user.online > 0) {
                        if (!onlineList) prev.online.push(user);
                        if (offlineList) {
                            prev.offline = prev.offline.filter((off) => off.id !== user.id)
                        }
                    } else {
                        if (!offlineList) prev.offline.push(user);
                        if (onlineList) {
                            prev.online = prev.offline.filter((off) => off.id !== user.id)
                        }
                    }

                    return prev
                })
            }
        }
    }, [serverMessage, loginInformation])

    return (
        <AuthContext.Provider
            value={{
                routes,
                online,
                isLogin: loginInformation.isLogin,
                hasLogout: loginInformation.hasLogout,
                LoginData: loginInformation.data,
                connections: connections,
                socketReady: webSocketReady,
                isLoading,
                setLoginStatus,
                tokenExpired,
                Logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const AuthContext = createContext({})
export default AuthProvider
