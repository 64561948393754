import React, { useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { Nav } from "react-bootstrap"

import { filterRecord } from "@helpers/GlobalFunctions"

const MobileHeader = ({ routes, handleClose = null }) => {
    return (
        <React.Fragment>
            <Nav className="flex-column">
                {routes.map((menu, index) => {
                    const dept = 1

                    let menuName = `sidebar-menu-${dept}-${index}`
                    if (menu.link) {
                        menuName = `sidebar-menu-${menu.link.replace(
                            /\//g,
                            ""
                        )}`
                    }

                    if (menu && !menu.hidden) {
                        return (
                            <ListMenu
                                dept={dept}
                                data={menu}
                                hasSubMenu={menu.children}
                                menuName={menuName}
                                key={menuName}
                                menuIndex={index}
                                handleClose={handleClose}
                            />
                        )
                    } else {
                        return null
                    }
                })}
            </Nav>
        </React.Fragment>
    )
}

const ListMenu = ({
    dept,
    data,
    hasSubMenu,
    menuName,
    menuIndex,
    handleClose,
}) => {
    const { pathname } = useLocation()
    const [activeMenus, setActiveMenus] = useState([])

    const handleArrowClick = (menuName) => {
        let newActiveMenus = [...activeMenus]

        if (newActiveMenus.includes(menuName)) {
            var index = newActiveMenus.indexOf(menuName)
            if (index > -1) {
                newActiveMenus.splice(index, 1)
            }
        } else {
            newActiveMenus.push(menuName)
        }

        setActiveMenus(newActiveMenus)
    }

    let isToggle = activeMenus.includes(menuName)
    if (hasSubMenu && !isToggle) {

        if(filterRecord(data.children, "link", pathname)) {
            handleArrowClick(menuName)
            isToggle = true
        }
    }

    return (
        <React.Fragment>
            {hasSubMenu ? (
                <Nav.Item
                    onClick={() => handleArrowClick(menuName)}
                    className={`my-3 position-relative mobile-nav-link mobile-nav-dropdown ${
                        isToggle ? "toggled" : ""
                    }`}
                >
                    <span className="text-truncate">{data.text}</span>
                </Nav.Item>
            ) : (
                <Nav.Link
                    as={NavLink}
                    to={data.link}
                    className="my-3 mobile-nav-link"
                    onClick={handleClose}
                >
                    {data.text}
                </Nav.Link>
            )}
            {hasSubMenu && (
                <SubMenu
                    dept={dept}
                    data={data.children}
                    toggle={isToggle}
                    menuIndex={menuIndex}
                />
            )}
        </React.Fragment>
    )
}

const SubMenu = ({ dept, data, toggle, menuIndex }) => {
    if (!toggle) {
        return null
    }

    dept = dept + 1

    return (
        <Nav className="flex-column">
            {data.map((menu, index) => {
                let menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`
                if (menu.link) {
                    menuName = `sidebar-submenu-${menu.link.replace(/\//g, "")}`
                }

                return (
                    <ListMenu
                        dept={dept}
                        data={menu}
                        hasSubMenu={menu.children}
                        menuName={menuName}
                        key={menuName}
                        menuIndex={index}
                    />
                )
            })}
        </Nav>
    )
}

export default MobileHeader
