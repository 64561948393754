import React from "react"
import { Toast } from "react-bootstrap"

import "./toast-extended.scss";

const ToastExtended = (props) => {

    const { destroy, title, description, duration = 4000, type = "success", autoHide = true } = props
    return (
        <Toast
            onClose={destroy}
            show={true}
            autohide={autoHide}
            bg={type}
            delay={duration}
        >
            <Toast.Header>
                <span className="me-2">
                    {type && (
                        <React.Fragment>
                            {type === "success" && <SuccessIcon />}
                            {type === "info" && <InfoIcon />}
                            {type === "warning" && <WarningIcon />}
                            {type === "danger" && <DangerIcon />}
                        </React.Fragment>
                    )}
                </span>
                <span className="fw-bold me-auto">{title}</span>
            </Toast.Header>
            {description && (
                <Toast.Body className="bg-white" style={{ whiteSpace: 'pre-wrap' }}>{description}</Toast.Body>
            )}
        </Toast>
    )
}

const InfoIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            role="img"
            aria-label="Info:"
            viewBox="0 0 16 16"
            className="p-1"
            fill="#084298"
        >
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </svg>
    )
}

const SuccessIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
            viewBox="0 0 16 16"
            className="p-1"
            fill="#0f5132"
        >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
    )
}

const WarningIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            role="img"
            aria-label="Warning:"
            viewBox="0 0 16 16"
            className="p-1"
            fill="#664d03"
        >
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
    )
}

const DangerIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            role="img"
            aria-label="Danger:"
            fill="#842029"
            viewBox="0 0 16 16"
            className="p-1"
        >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>
    )
}

const shouldRerender = (prevProps, nextProps) => {
    return prevProps.id === nextProps.id
}

export default React.memo(ToastExtended, shouldRerender)
