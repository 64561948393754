import React, { useEffect, useRef, useLayoutEffect } from "react"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Button, Image } from "react-bootstrap"

import IdleTimer from "@helpers/IdleTimer"

import { ScrollToTop } from "@components/ScrollToTop"
import { LoadingState } from "@components/LoadingState"

import { Header } from "@components/Header"

import ErrorImage from "@assets/illustrations/error/page-not-found.svg"
import { useAuth } from "./context"

const App = () => {
    const { routes, isLoading, isLogin, tokenExpired } = useAuth()
    const { pathname } = useLocation()
    
    let tokenExpireTimer = useRef(null);
    let tokenChecked = useRef(false);

    useLayoutEffect(() => {
        if(isLogin && !tokenChecked.current) {
            tokenChecked.current = true;
            tokenExpireTimer.current = new IdleTimer({
                timeout: 1800, //expire after 30 min
                onTimeout: () => {
                    tokenExpired()
                },
                onExpired: () => {
                    tokenExpired()
                },
            })
            
        }

    }, [isLogin, tokenExpired])

    useLayoutEffect(() => {

        if(isLogin === false) {
            if(tokenExpireTimer.current) {
                tokenExpireTimer.current?.resetStorage()
                tokenExpireTimer.current = null
            }

            if(localStorage.getItem("_expiredTime")) {
                localStorage.removeItem("_expiredTime")
            }

            if(tokenChecked.current) {
                tokenChecked.current = false;
            }
        }

    }, [pathname, isLogin])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingState />
            ) : (
                <React.Suspense fallback={<LoadingState />}>
                    {routes?.length > 0 && (
                        <Routes>
                            <React.Fragment>
                                {RenderRoutes(routes)}

                                {routes[0].link && (
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate
                                                to={routes[0].link}
                                                replace
                                            />
                                        }
                                    />
                                )}
                                <Route path="*" element={<Error />} />
                            </React.Fragment>
                        </Routes>
                    )}
                    <ScrollToTop />
                </React.Suspense>
            )}
        </React.Fragment>
    )
}

const RenderRoutes = (routes) => {
    return routes.map((route, indx) => {
        const RouteComponent = route.component
        return route.link && RouteComponent ? (
            <Route
                key={indx}
                path={route.link}
                element={ComponentWrapper(RouteComponent)}
            />
        ) : (
            RenderRoutes(route.children)
        )
    })
}

const ComponentWrapper = (WrappingComponent) => {
    const WrapComponent = () => {
        return (
            <React.Fragment>
                <Header />
                <WrappingComponent />
            </React.Fragment>
        )
    }

    return <WrapComponent />
}

const Error = () => {
    return (
        <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center py-5">
            <div className="text-center">
                <div>
                    <h1 className="mb-1 fs-4 fw-bold">Page Not Found 🕵🏻‍♀️</h1>
                    <p className="mb-2 small">
                        Oops! 😖 The requested URL was not found on this server.
                    </p>
                    <Button as={Link} to="/" className="mt-3">
                        Back to home
                    </Button>
                </div>
                <Image fluid src={ErrorImage} className="my-5" />
            </div>
        </div>
    )
}

export default App
